import { _t } from '../utils/messages';

// eslint-disable-next-line import/prefer-default-export
export const responseMessages = {
  successResponse: {
    title: () => _t('successResponse.title', 'félicitation'),
    subtitle: () => _t('successResponse.subtitle', 'Et voilà ! C’est fini'),
    body: {
      heading: () =>
        _t(
          'successResponse.body.heading',
          'Félicitations ! Votre paiement est réussi.',
        ),
      description: () =>
        _t(
          'successResponse.body.description',
          `Vous recevrez un e-mail dans quelques minutes avec votre reçu de
              paiement.`,
        ),
      extra: () =>
        _t(
          'successResponse.body.extra',
          `Vous serez recontacté si vous faites partie des heureux gagnants
              de notre grande`,
        ),
      link: () => _t('successResponse.body.link', `Tombola`),
    },
    action: {
      mainBtn: () =>
        _t('successResponse.action.mainBtn', `Télécharger le reçu`),
      goBack: () => _t('successResponse.action.goBack', `Retour à l'acceuil`),
    },
  },
  failedResponse: {
    title: () => _t('failedResponse.title', 'Échec de paiement'),
    subtitle: () => _t('failedResponse.subtitle', 'Et voilà ! C’est fini'),
    body: {
      heading: () =>
        _t(
          'failedResponse.body.heading',
          'Nous sommes désolés, votre paiement n’a pas pu aboutir',
        ),
      description: () =>
        _t(
          'failedResponse.body.description',
          `Vous pouvez réessayer plus tard ou payer votre vignette à travers
              les autres canaux de la banque`,
        ),
      link: () => _t('failedResponse.body.link', `Tombola`),
    },
    action: {
      goBack: () => _t('failedResponse.action.goBack', `Retour à l'acceuil`),
    },
  },
};
