import { useCallback, useContext, useEffect, useMemo } from 'react';
import { navigate } from 'gatsby';
import { GlobalContext } from '../context';

export default function usePaymentResponse() {
  const {
    state: { paymentResponse },
  } = useContext(GlobalContext);

  const handleResponse = useCallback(async () => {
    const weirdParam =
      // eslint-disable-next-line no-nested-ternary
      paymentResponse !== null
        ? 'cf_params_global' in paymentResponse
          ? paymentResponse.cf_params_global
          : undefined
        : undefined;

    if (!weirdParam) {
      await navigate('/');
    }
  }, [paymentResponse]);

  useEffect(() => {
    handleResponse();
  }, [handleResponse]);

  return useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      paymentResponse !== null
        ? 'cf_params_global' in paymentResponse
          ? paymentResponse.cf_params_global
          : undefined
        : undefined,
    [paymentResponse],
  );
}
