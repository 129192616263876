import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Block = styled.div`
  margin-bottom: 120px;

  @media screen and (max-width: 640px) {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > svg {
    margin-bottom: 20px;

    @media screen and (max-width: 640px) {
      width: 60px;
    }
  }
  h2 {
    font-family: ${props => props.theme.fonts.Montserrat};
    font-weight: ${props => props.theme.fontWeights.Montserrat.extraBold};
    font-size: 21px;
    text-transform: uppercase;
    margin-bottom: 10px;
    @media screen and (max-width: 640px) {
      font-size: 14px;
      text-align: center;
    }
  }
  p {
    font-family: ${props => props.theme.fonts.SourceSansPro};
    font-size: 18px;
    @media screen and (max-width: 640px) {
      font-size: 14px;
      line-height: normal;
      text-align: center;
    }
  }
`;
